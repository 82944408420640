import "./home.css";
import banner from "../img/hero-bg.png";
import chirper from "../img/chirper.png";
import wefund from "../img/wefund2.png";
import igweb from "../img/igweb.png";
import office from "../img/office.png";
import ig from "../img/ig.png";
import profileImg from "../img/profile-img.jpg";
import { useState, useEffect, useRef } from "react";
import portfolio1 from "../img/masonry-portfolio/masonry-portfolio-1.jpg";
import portfolio2 from "../img/masonry-portfolio/masonry-portfolio-2.jpg";
import portfolio3 from "../img/masonry-portfolio/masonry-portfolio-3.jpg";
import portfolio4 from "../img/masonry-portfolio/masonry-portfolio-4.jpg";
import portfolio5 from "../img/masonry-portfolio/masonry-portfolio-5.jpg";
import portfolio6 from "../img/masonry-portfolio/masonry-portfolio-6.jpg";
import portfolio7 from "../img/masonry-portfolio/masonry-portfolio-7.jpg";
import portfolio8 from "../img/masonry-portfolio/masonry-portfolio-8.jpg";
import portfolio9 from "../img/masonry-portfolio/masonry-portfolio-9.jpg";
import Isotope from 'isotope-layout';
import GLightbox from 'glightbox';
import Typed from 'typed.js';

const Home = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const isotopeRef = useRef(null);
  const [activeFilter, setActiveFilter] = useState('*');
  const typedRef = useRef(null);
  const [activeSection, setActiveSection] = useState('hero');

  const StatsItem = ({ icon, end, label }) => {
    const [count, setCount] = useState(0);
    const [hasAnimated, setHasAnimated] = useState(false);
    const countRef = useRef(null);
    
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          const [entry] = entries;
          if (entry.isIntersecting && !hasAnimated) {
            // Start animation when element comes into view
            setHasAnimated(true);
            
            const duration = 1000; // 1 second duration
            const steps = 50; // Number of steps
            const stepValue = end / steps;
            const stepDuration = duration / steps;
            let currentStep = 0;

            const timer = setInterval(() => {
              currentStep++;
              setCount(Math.min(Math.round(stepValue * currentStep), end));
              
              if (currentStep >= steps) {
                clearInterval(timer);
              }
            }, stepDuration);

            return () => clearInterval(timer);
          }
        },
        { threshold: 0.5 } // Trigger when 50% of the element is visible
      );

      if (countRef.current) {
        observer.observe(countRef.current);
      }

      return () => {
        if (countRef.current) {
          observer.unobserve(countRef.current);
        }
      };
    }, [end, hasAnimated]);

    return (
      <div className="col-lg-3 col-md-6 d-flex flex-column align-items-center" ref={countRef}>
        <i className={`bi ${icon}`}></i>
        <div className="stats-item">
          <span className="purecounter">{count}</span>
          <p>{label}</p>
        </div>
      </div>
    );
  };

  const SkillItem = ({ skill, percentage }) => {
    return (
      <div className="progress">
        <span className="skill">
          <span>{skill}</span> <i className="val">{percentage}%</i>
        </span>
        <div className="progress-bar-wrap">
          <div 
            className="progress-bar" 
            role="progressbar" 
            aria-valuenow={percentage} 
            aria-valuemin="0" 
            aria-valuemax="100"
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
      </div>
    );
  };

  const ResumeItem = ({ title, period, organization, location, description, items }) => {
    return (
      <div className="resume-item">
        <h4>{title}</h4>
        {period && <h5>{period}</h5>}
        {organization && <p><em>{organization}</em></p>}
        {location && <p><em>{location}</em></p>}
        {description && <p>{description}</p>}
        {items && (
          <ul>
            {items.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  const PortfolioItem = ({ image, title, category, filter }) => {
    return (
      <div className={`portfolio-item ${filter}`}>
        <img src={image} className="img-fluid" alt={title} />
        <div className="portfolio-info">
          <h4>{title}</h4>
          <p>Lorem ipsum, dolor sit</p>
          <a 
            href={image} 
            title={title} 
            data-gallery={`portfolio-gallery-${category}`} 
            className="glightbox preview-link"
          >
            <i className="bi bi-zoom-in"></i>
          </a>
          <a href="#" title="More Details" className="details-link">
            <i className="bi bi-link-45deg"></i>
          </a>
        </div>
      </div>
    );
  };

  const ServiceItem = ({ color, icon, title, description, delay, img, url }) => {
    return (
      <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={delay}>
        <div className={`service-item item-${color} position-relative`}>
        
          <div style={{marginBottom:"40px"}}><img src={img} alt="image" style={{width:"100%"}} /></div>
          <a href={url} className="stretched-link" target="_blank" rel="noreferrer">
            <h3>{title}</h3>
          </a>
          <p>{description}</p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    // Initialize GLightbox
    GLightbox({
      selector: '.glightbox'
    });

    // Initialize Isotope with a slight delay to ensure content is loaded
    setTimeout(() => {
      isotopeRef.current = new Isotope('.isotope-container', {
        itemSelector: '.portfolio-item',
        layoutMode: 'masonry',
        masonry: {
          columnWidth: '.portfolio-item'
        }
      });
    }, 200);

    // Cleanup
    return () => {
      if (isotopeRef.current) {
        isotopeRef.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    const typed = new Typed('.typed', {
      strings: ['Designer', 'Developer', 'Photographer'],
      typeSpeed: 50,
      backSpeed: 30,
      backDelay: 2000,
      loop: true
    });

    // Cleanup
    return () => {
      typed.destroy();
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const sections = [
        'hero',
        'about',
        'stats',
        'skills',
        'resume',
        'portfolio',
        'services',
        'contact'
      ];

      const sectionElements = sections.map(id => {
        const element = document.getElementById(id);
        if (element) {
          return {
            id,
            offset: element.offsetTop - 100, // Adjust offset as needed
            height: element.offsetHeight
          };
        }
        return null;
      }).filter(Boolean);

      const scrollPosition = window.scrollY + window.innerHeight / 3;

      for (let i = 0; i < sectionElements.length; i++) {
        const { id, offset, height } = sectionElements[i];
        const nextOffset = sectionElements[i + 1]?.offset || Infinity;

        if (scrollPosition >= offset && scrollPosition < nextOffset) {
          setActiveSection(id);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    // Initial check
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
    if (isotopeRef.current) {
      isotopeRef.current.arrange({
        filter: filter === '*' ? '' : filter
      });
    }
  };

  return (
    <>
      <header className="header d-flex flex-column justify-content-center">
      {/*  <i 
          className="header-toggle d-xl-none bi bi-list"
          onClick={() => setIsNavOpen(!isNavOpen)}
        />*/} 

        <nav className={`navmenu ${isNavOpen ? 'active' : ''}`}>
          <ul>
            <li>
            <span class="material-symbols-outlined navs">home</span>
              <a 
                href="#hero" 
                className={activeSection === 'hero' ? 'active' : ''}
              >
                
                <span style={{marginLeft:"30px"}}>Home</span>
              </a>
            </li>
            <li>
            <span class="material-symbols-outlined navs">person</span>
              <a 
                href="#about" 
                className={activeSection === 'about' ? 'active' : ''}
              >
                <i className="bi bi-person navicon"></i>
                <span style={{marginLeft:"30px"}}>About</span>
              </a>
            </li>

            <li>
            <span class="material-symbols-outlined navs">school</span>
              <a 
                href="#skills" 
                className={activeSection === 'skills' ? 'active' : ''}
              >
                <i className="bi bi-person navicon"></i>
                <span style={{marginLeft:"30px"}}>Skills</span>
              </a>
            </li>

            <li>
            <span class="material-symbols-outlined navs">description</span>
              <a 
                href="#resume" 
                className={activeSection === 'resume' ? 'active' : ''}
              >
                <i className="bi bi-file-earmark-text navicon"></i>
                <span style={{marginLeft:"30px"}}>Resume</span>
              </a>
            </li>
           
            <li>
            <span class="material-symbols-outlined navs">language</span>
              <a 
                href="#services" 
                className={activeSection === 'services' ? 'active' : ''}
              >
                <i className="bi bi-hdd-stack navicon"></i>
                <span style={{marginLeft:"30px"}}>Services</span>
              </a>
            </li>
           
          </ul>
        </nav>
      </header>

      <main className="main">
        {/* Hero Section */}
        <section id="hero" className="hero section light-background">
          <img src={banner} alt="banner"  />
          <div className="container" data-aos="zoom-out">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <h2>Lerato Letwaba</h2>
                <p>I'm <span className="typed"></span></p>
                <div style={{paddingTop: '30px'}}>
                  <a href="https://drive.google.com/file/d/14UnQlJqe4kPfnfUWFrtSMF6YhXYwftPx/view?usp=sharing" className="cv">Download CV</a>

                  <a href="https://drive.google.com/file/d/1ZQWjA7NZWAwwYPZx2ZG0Ew6DwXRtMA95/view?usp=sharing" className="cv">Download<span> Design Portfolio</span> </a>
                </div>
               {/* <div className="social-links">
                  <a href="#"><i className="bi bi-twitter-x"></i></a>
                  <a href="#"><i className="bi bi-facebook"></i></a>
                  <a href="#"><i className="bi bi-instagram"></i></a>
                  <a href="#"><i className="bi bi-linkedin"></i></a>
                </div>*/} 
              </div>
            </div>
          </div>
        </section>

        {/* Add remaining sections here... */}


        {/* About Section */}
        <section id="about" className="about section">
          {/* Section Title */}
          <div className="container section-title" data-aos="fade-up">
            <h2>PROFESSIONAL SUMMARY</h2>
            <p>To gain more experience in a company that expects high
standards and encourages individual growth and
self-development.
Aiming to meet quality standards, participate fully in
project completion on time, work with minimal supervision,
and contribute meaningfully to improving the company's
image
Make more deals. to investigate other IT areas such as
programming and networking Above all, I am eager to
learn</p>
          </div>

          <div className="container line" data-aos="fade-up" data-aos-delay="100">
            <div className="row gy-4 justify-content-center">
             {/* <div className="col-lg-4">
                <img src={profileImg} className="img-fluid" alt="Profile" />
              </div>*/} 
              <div className="col-lg-8 content">
                <h2>Designer / Developer.</h2>
                
                <div className="row">
                  <div className="col-lg-6">
                    <ul>
                      <li><i className="bi bi-chevron-right"></i> <strong>Name</strong> <span>Lerato Letwaba</span></li>
                      <li><i className="bi bi-chevron-right"></i> <strong>Experience:</strong> <span>10+</span></li>
                      <li><i className="bi bi-chevron-right"></i> <strong>Phone:</strong> <span>+27 60 976 9492</span></li>
                    
                    </ul>
                  </div>
                  <div className="col-lg-6">
                    <ul>
                    <li><i className="bi bi-chevron-right"></i> <strong>Freelance:</strong> <span>Available</span></li>
                      <li><i className="bi bi-chevron-right"></i> <strong>Location:</strong> <span>Gauteng, Johannesburg, 2190</span></li>
                      <li><i className="bi bi-chevron-right"></i> <strong>Email:</strong> <span>lletwaba@yahoo.com</span></li>
                     
                    </ul>
                  </div>
                </div>
              
              </div>
            </div>
          </div>
        </section>

        {/* Stats Section */}
        {/* 
        <section id="stats" className="stats section">
          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className="row gy-4">
              <StatsItem 
                icon="bi-emoji-smile" 
                end={232} 
                label="Happy Clients" 
              />
              <StatsItem 
                icon="bi-journal-richtext" 
                end={521} 
                label="Projects" 
              />
              <StatsItem 
                icon="bi-headset" 
                end={1463} 
                label="Hours Of Support" 
              />
              <StatsItem 
                icon="bi-people" 
                end={15} 
                label="Hard Workers" 
              />
            </div>
          </div>
        </section>
        */}

        {/* Skills Section */}
        <section id="skills" className="skills section">
          <div className="container section-title" data-aos="fade-up">
            <h2>Skills</h2>
            <p> FullStack Development | Frontend Development | Digital Design <br />Email Marketing/Development | Digital Marketing | Photography</p>
          </div>

          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className="row skills-content skills-animation">
              <div className="col-lg-6">
              <SkillItem skill="Illustrator" percentage={95} />
              <SkillItem skill="Photoshop" percentage={95} />
              <SkillItem skill="After Effects" percentage={50} />
              <SkillItem skill="Light Room" percentage={60} />
                <SkillItem skill="HTML" percentage={90} />
                <SkillItem skill="CSS" percentage={80} />
                <SkillItem skill="JavaScript" percentage={65} />
               
              
              </div>

              <div className="col-lg-6">
             
            
              <SkillItem skill="React Js" percentage={50} />
              <SkillItem skill="Next Js" percentage={40} />
                <SkillItem skill="Boostrap" percentage={80} />
                <SkillItem skill="Node Js" percentage={30} />
                <SkillItem skill="Sass" percentage={40} />
                <SkillItem skill="Git/GitHub" percentage={25} />
                <SkillItem skill="MySql / NoSql" percentage={55} />
                
                
               
              </div>
            </div>
          </div>
        </section>

        {/* Resume Section */}
        <section id="resume" className="resume section">
          <div className="container section-title" data-aos="fade-up">
            <h2>Resume</h2>
           
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                <h3 className="resume-title">Education</h3>
                <ResumeItem 
                  title="Advertising NQF 4"
                  period="2008 - 2009"
                  organization="Vega [Imagination Lab]"
                  description="It included Advertising, Digital Design & Marketing, and how to create a campaign from start to finish. "  
                />

<h3 className="resume-title">Professional Experience</h3>
                <ResumeItem 
                  title="Email Developer - Digital Designer"
                  period="2013 - 2015"
                  organization="Everlytics"
                  items={[
                    "Developing UX / UI for the Everlytic new registration experience",
                    "Using Best practice HTML, CSS and Templating language For responsive emails",
                    "Trouble Shooting email code for the Everlytics clients",
                    "Maintaing websites and developing email campaigns for Everlytic clients using A/B testing for optimization","Creating Motion Graphics for Events with Audio desgin siutable for the animation"
                  ]}
                />
              
              </div>

              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                
                <ResumeItem 
                  title="Full Stack Developer - Digital Desinger "
                  period="2023 - Present"
                  organization="I-G3N"
                  items={[
                    "Working with Electric engineers to Creating the battery management system motherboard for I-G3N High Voltage batteries, writing code in C++ [Esp32 dev Module] to interface with the microprocessor, and utilizing IoT to interface with the front-end dashboard system",
                    "Developing a support ticket web platform that will allow I-G3N to outsource the support jobs by allowing the installers to login and see current tickets",
                    "Creating Digital Marketing Materials and Implementing Social Media Optimization for High Perfomance Campaigns"
                  ]}
                />
                <ResumeItem 
                  title="Graphic Designer"
                  period="2015 - 2021"
                  organization="Tarsus Distribution"
                  items={[
                    "Developing Digital and Mail Marketing Campaings, Promotions, Eventand Exhibition Materials, Social materials, 3D Display Mock up etc. for all Project Managers and Sales Department.",
                    "Working with The Public Relation Department to develop Internal and External Communications",
                    "Managing and Monitoring Email Listing for all Accounts",
                    "Developing Presentations, Proposals and Strategy Materials"
                  ]}
                />
              </div>
            </div>
          </div>
        </section>

  

        {/* Services Section */}
        <section id="services" className="services section">
          <div className="container section-title" data-aos="fade-up">
            <h2>Portfolio</h2>
         
          </div>

          <div className="container">
            <div className="row gy-4">
              <ServiceItem
                img={chirper}
                url="https://www.auto.chirper.co.za/"
                title="Chirper Auto"
                description="Next Js / Tailwind CSS / Supabase / Git-GitHub / Render"
                delay="100"
              />
              <ServiceItem
              img={office}
                url="https://office.chirper.co.za/"
                title="Chirper Office"
                description="React JS / Sass / Css / Bootstrap / Git-GitHub / Digital Ocean"
                delay="200"
              />
              <ServiceItem
                img={ig}
                url="#"
                title="i-G3N"
                description="React JS / Css / JavaScript / Bootstrap / Supabase"
                delay="200"
              />
                <ServiceItem
              img={wefund}
                url="https://www.wefundclaims.co.za/index.html"
                title="WeFund Claims"
                description="HTML / Css / JavaScript / Bootstrap / C-Panel"
                delay="200"
              />
               <ServiceItem
              img={igweb}
                url="https://www.igpower.world/"
                title="IG Power"
                description="HTML / Css / JavaScript / Bootstrap / C-Panel / PHP"
                delay="200"
              />

                  {/* 
              <ServiceItem
                color="red"
                icon="bi-bounding-box-circles"
                title="Asperiores Commodit"
                description="Non et temporibus minus omnis sed dolor esse consequatur. Cupiditate sed error ea fuga sit provident adipisci neque."
                delay="400"
              />
              <ServiceItem
                color="indigo"
                icon="bi-calendar4-week"
                title="Velit Doloremque"
                description="Cumque et suscipit saepe. Est maiores autem enim facilis ut aut ipsam corporis aut. Sed animi at autem alias eius labore."
                delay="500"
              />
              <ServiceItem
                color="pink"
                icon="bi-chat-square-text"
                title="Dolori Architecto"
                description="Hic molestias ea quibusdam eos. Fugiat enim doloremque aut neque non et debitis iure. Corrupti recusandae ducimus enim."
                delay="600"
              />*/}


            </div>
          </div>
        </section>

      </main>

      <footer id="footer" className="footer position-relative">
        <div className="container">
         
          <div className="container">
            <div className="copyright">
              <span>Copyright</span> <strong className="px-1 sitename"></strong> <span>All Rights Reserved</span>
            </div>
           
          </div>
        </div>
      </footer>

     
    </>
  );
};

export default Home;
